<template>
  <WrapModal
    v-model:visible="props.visible"
    formMode
    tooSmall
    :width="600"
    modealTitle="查看"
    :footer="props.modaltype === ''"
    @cancel="onCancel"
  >
    <section class="content show-scrollbar">
      <section>
        <a-descriptions
          title=""
          layout="vertical"
          bordered
          :column="2"
          size="small"
          class="descript"
          style="border-top: 1px solid #f0f0f0"
        >
          <a-descriptions-item label="归档流程名称" style="vertical-align: top;">
            {{ props.modalRecord.name }}
          </a-descriptions-item>
          <a-descriptions-item label="适用范围">
            所有机构
          </a-descriptions-item>
          <a-descriptions-item label="审批人" style="vertical-align: top;">
            部门主管
          </a-descriptions-item>
          <a-descriptions-item label="审批人为空时">
            自动通过
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="" layout="vertical" bordered :column="1" size="small" class="descript">
          <a-descriptions-item label="审批人与之前节点存在重复时" style="vertical-align: top;">
            自动通过
          </a-descriptions-item>
        </a-descriptions>
      </section>
    </section>
  </WrapModal>
</template>
<script setup>
import { ref, reactive, defineProps, onMounted } from 'vue'
const props = defineProps(['visible', 'modalVisible', 'modalRecord'])
import WrapModal from '@/components/WrapModal/index'
import { addTypeList, setTypeList } from '@/apis/businessManage'
import { LinkOutlined } from '@ant-design/icons-vue'
import { cmsNotice } from '@/utils/utils'
// const emit=defineEmits(['changeVisible'])
const formData = reactive({
  typeName: '',
  typeNumber: '',
  explain: ''
})
//提交按钮是否加载
onMounted(() => {
  console.log(props.modalRecord)
})

//关闭
const onCancel = () => {
  // emit('changeVisible')
  console.log('关闭！！')
  //重置formData内容
  props.modalVisible()
}
</script>
<style lang="less" scoped>
.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;

  &:hover {
    border-color: transparent;
  }
}

.view-table-header {
  display: flex;
  // margin-top: 10px;

  .table-title {
    height: 36px;
    width: 306px;
    line-height: 36px;
    background: #fafafa;
    color: gray;
    padding-left: 12px;
    border: 1px solid #e6e6e6;
  }
}

.view-table-content {
  display: flex;

  .table-content {
    width: 306px;
    border: 1px solid #e6e6e6;
    padding: 12px;
    min-height: 36px;
  }
}

.view-table-explain {
  padding: 12px;
  background: #fafafa;
  color: gray;
  border: 1px solid #e6e6e6;
}

.table-explain {
  // width: 610px;
  min-height: 36px;
  padding: 12px;
  border: 1px solid #e6e6e6;
}
.content {
  // padding: 24px 24px;
  max-height: 519px;
  overflow-y: auto;

  :deep(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
  }

  :deep(.ant-descriptions-item-content) {
    color: rgba(0, 0, 0, 0.65);
  }

  .mark {
    > p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      margin-bottom: 8px;
    }

    :deep(.ant-input-textarea-show-count::after) {
      top: -25px;
    }
  }

  .descript {
    :deep(.ant-descriptions-item-label) {
      width: 50%;
    }
  }

  :deep(.ant-descriptions-view:nth-of-type(1)) {
    border-top: none;
  }
}
</style>
